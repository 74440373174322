<template>
  <div>
    <!-- uploadC9A809AE-5B68-4F14-9DF8-147533D9D03B.swf -->
    <!-- pic594F654DEB3F553D7D7EBDEBB3CBDFB6.jpg -->
    <img class="img-large rounded" src="">
    <p>
      A LEGO Universe city will feature a spectacular LEGO Club house inspired by fan creations. Now we're looking for your lofty ideas on how to create a cool lookout tower! It'll be a sky-high spot for Club members to keep an eye out for evil invaders or to just enjoy stunning city views!
    </p>
    <p>
      Step right up to unlock the <router-link :to="{ name: 'creation-lab-list', params: {challengeId: '50190DA0-2828-4618-BD1D-A25F489B81DE'} }">latest creative challenge</router-link> and claim a special two-part gift!
    </p>
  </div>
</template>
